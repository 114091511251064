@import 'palette';
html {
  --primary-color: #00787A;
   color: $dark-off-black;

  svg {
    display: inline-block;
  }

  hr {
    border-top: 1px solid #949494;
  }
}

*, ::before, ::after {
  border-color: $mid-gray;
  scroll-behavior: smooth;
}

.App {
  height: 100%;
  font-family: Montserrat;
}
.showcase-content-wrapper, .knovio-content-wrapper {
  @extend .w-screen;
  height: calc(100% - 48px);
}

.navbar-item-label:hover {
  color: var(--primary-color);
}

.navbar-item-label-active > div {
  box-shadow: 0 -1px inset $white;
  height: 65%;
}

.navbar-item-label-active > span {
  color: $dark-off-black;
  box-shadow: 0 -1px inset $dark-off-black;
}

.main-content-wrapper, .rich-text-content-wrapper {
  position: relative;
  padding-bottom: 3rem;
  min-height: calc(100% - 128px);

  &.no-footer {
    min-height: calc(100% - 48px);
  }

  > div {
    max-width: 60rem;
    margin: 2rem auto 0;
  }

  a:not(.no-underline) {
    text-decoration: underline;
  }
}

.background-img {
  object-fit: cover;
  position: absolute;
  height: calc(100% - 330px);
  max-height: 580px;
  width: 100%;
  top: 330px;
  left: 0;
  z-index: -1;
}

.h-70 {
  height: 70%;
  max-height: calc(100% - 9rem);
}
.min-h-168px {
  min-height: 168px;
}
.min-h-24rem {
  min-height: 24rem;
}

.bg-opacity-90 {
  @media screen
  and (min-width: 768px) {
    --bg-opacity: 0.95;
  }
}

.bg-white-opacity-95 {
  background: rgba(255, 255, 255, 0.95);
}

.mt-29px {
  margin-top: 29px;
}

.p-10px {
  padding: 10px;
}

.ml-10px {
  margin-left: 10px;
}

.mx-10px {
  margin-left: 10px;
  margin-right: 10px;
}

.py-10px {
  padding: 10px 0;
}

.w-112px {
  width: 112px;
}

.w-140px {
  width: 140px;
}
.w-50px {
  width: 50px;
  min-width: 50px;
}
.w-45px {
  width: 45px;
  min-width: 45px;
}
.w-246px {
  width: 246px;
}
.w-300px {
  width: 300px;
}
.w-342px {
  width: 342px;
}
.max-w-40vw {
  max-width: 40vw;
}

.text-4-25xl {
  font-size: 2.5rem;
}

.font-weight-500 {
  font-weight: 500;
}

.big-button {
  width: 128px;
  height: 48px;
}
.shadow-gray {
  box-shadow: 0 0 20px 0 rgba($black, 0.3);
}
.sm-shadow-gray {
  box-shadow: 0 0 6px 0 rgba($black, 0.2)
}

.text-primary {
  color: var(--primary-color);
}
.text-black {
  color: $black;
}
.fill-primary {
  fill: var(--primary-color);
}
.fill-gray {
  fill: $gray-darker;
}
.fill-white {
  fill: $white;
}
.fill-off-black {
  fill: $off-black;
}
.fill-off-black-shadowed {
  fill: rgba($off-black, 0.75);
}
.text-gray {
  color: $gray-darker;
}
.text-white {
  color: $white;
}
.text-light-blue {
  color: $light-blue;
}
.text-off-black {
  color: $off-black;
}
.text-dark-off-black {
  color: $dark-off-black;
}
.text-off-black-shadowed {
  color: rgba($off-black, 0.75);
}
.text-dark-red {
  color: #dd0000;
}
.text-error {
  color: $error;
}
.bg-blue {
  background-color: $blue;
}
.bg-gray-11 {
  background-color: rgba(0,0,0,0.03);
}
.bg-gray-50 {
  background-color: rgba(0,0,0,0.5);
}
.bg-gray-75 {
  background-color: rgba(0,0,0,0.75);
}
.bg-cool-gray {
  background-color: $cool-gray;
}
.bg-white {
  background: $white;
}
.bg-primary {
  background-color: var(--primary-color);
}
.bg-cool-gray-hover:focus-visible {
  outline: none;
}
.bg-cool-gray,
.bg-cool-gray-hover:not(.bg-primary):hover,
.bg-cool-gray-hover:focus-visible {
  background-color: $cool-gray;

  > * {
    z-index: 1;
  }

}

.z-2 {
  z-index: 2;
}

.flex-equal {
  flex: 1 1;
  flex-basis: 0;
}

@for $size from 10 through 40 {
  .font-size-#{$size}px {
    font-size: #{$size}px;
  }
}

.login-wrapper {
  @media screen
  and (min-width: 768px) {
    padding: 35px 40px 30px 40px;
  }
}
@for $lines from 1 through 10 {
  .truncate-advanced-#{$lines} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$lines};
    -webkit-box-orient: vertical;
  }
}
@for $size from 1 through 10 {
  .rounded-#{$size}px {
    border-radius: #{$size}px;
  }
}

@for $height from 1 through 40 {
  .line-height-#{$height}px {
    line-height: #{$height}px !important;
  }
}

.header-line {
  @media screen
  and (min-width: 768px) {
    line-height: 34px;
  }
}
.h1-font-size{
  font-size: 250%;
}

.header-font-size {
  @media screen
  and (min-width: 768px) {
    font-size: 181.25%;
  }
  font-size: 23px
}

.negative-margin {
  margin-left: -40px;
  margin-right: -40px;
}

@for $pixels from 1 through 40 {
  .py-#{$pixels}px {
    padding-left: #{$pixels}px;
    padding-right: #{$pixels}px;
  }
}

$directions: 'top', 'right', 'bottom', 'left';
@for $size from 0 through 25 {
  .m-#{$size}px {
    margin: #{$size}px;
  }

  @each $direction in $directions {
    .m-#{$direction}-#{$size}px {
      margin-#{$direction}: #{$size}px !important;
    }
  }
}
@for $size from 0 through 20 {
  .p-#{$size}px {
    padding: #{$size}px;
  }

  @each $direction in $directions {
    .p-#{$direction}-#{$size}px {
      padding-#{$direction}: #{$size}px !important;
    }
  }
}
@for $size_1 from 0 through 1 {
  @for $size_2 from 0 through 9 {
    .ls-#{$size_1}-#{$size_2}px {
      letter-spacing: #{$size_1 + calc($size_2 / 10)}px;
    }
  }
}

@for $size from 1 through 10 {
  .opacity-#{$size}0 {
    opacity: #{calc($size / 10)};
  }
}

.word-break {
  word-break: break-word;
}

.pre-line {
  white-space: pre-line;
}

.outline-auto {
  outline: auto;
}

.input-outline:focus {
  outline: 2px solid var(--primary-color);
}

.on-hover-shadow:not(:disabled):hover {
  border-style: none;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
.on-hover-shadow:not(.common-btn):hover {
  background: $white;
}
.outline {
  outline: 1px solid rgba($dark-off-black, 0.6);
}
.outline-mid-gray {
  outline-color: $mid-gray;
}
.outline-green-blue {
  outline-color: $green-blue;
}

.m-x-auto{
  margin-left: auto;
  margin-right: auto;
}

.outline-auto-visible {
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: auto;
    outline-color: black;
  }
}
.pointer-events {
  pointer-events: none;
}
/* Tooltip styling */
[data-tooltip] {
  display: inline-block;
  position: relative;
}

[data-tooltip]:before {
  content: attr(data-tooltip);
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.4;
  min-width: 100%;
  text-align: center;
  border-radius: 4px;
  white-space: break-spaces;
  transition: 0s;
  visibility: hidden;
}

[data-tooltip-position="top"]:before,
[data-tooltip-position="bottom"]:before {
  left: 50%;
  transform: translateX(-50%);
}

[data-tooltip-position="right"]:before,
[data-tooltip-position="left"]:before {
  top: 50%;
  transform: translateY(-50%);
}

[data-tooltip-position="top"]:before {
  bottom: 100%;
  margin-bottom: 6px;
}

[data-tooltip-position="right"]:before {
  left: 100%;
  margin-left: 6px;
}

[data-tooltip-position="bottom"]:before {
  top: 100%;
  margin-top: 6px;
}

[data-tooltip-position="left"]:before {
  right: 100%;
  margin-right: 6px;
}

[data-tooltip]:after {
  content: '';
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

[data-tooltip-position="top"]:after,
[data-tooltip-position="bottom"]:after {
  left: 50%;
  margin-left: -6px;
}

[data-tooltip-position="right"]:after,
[data-tooltip-position="left"]:after {
  top: 50%;
  margin-top: -6px;
}

[data-tooltip-position="top"]:after {
  bottom: 100%;
  border-width: 6px 6px 0;
  border-top-color: #000;
}

[data-tooltip-position="right"]:after {
  left: 100%;
  border-width: 6px 6px 6px 0;
  border-right-color: #000;
}

[data-tooltip-position="bottom"]:after {
  top: 100%;
  border-width: 0 6px 6px;
  border-bottom-color: rgba(0, 0, 0, 0.8);
}

[data-tooltip-position="left"]:after {
  right: 100%;
  border-width: 6px 0 6px 6px;
  border-left-color: #000;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  transition-delay: 1s;
}

.max-h-400 {
  max-height: 400px;
}

.max-h-32 {
  height: 8rem;
}

.h-34px {
  height: 34px;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #777;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.translate-element {
  transform: translateY(25%);
}

.replays-join-button {
  min-width: 84px;
  margin-right: 10px;
}

.featured-card {
  .plenary {
    margin-left: 0px;
  }
}

.leading-extra-tight {
  line-height: 1.1;
}

.tracking-custom {
  &-0-6 {
    letter-spacing: 0.6;
  }

  &-0-7 {
    letter-spacing: 0.7;
  }

  &-1-91 {
    line-height: 1.91;
  }

  &-1-24 {
    line-height: 1.24;
  }

  &-1-3 {
    line-height: 1.3;
  }

  &-1-4 {
    line-height: 1.4;
  }

  &-1-5 {
    line-height: 1.5;
  }

  &-1-7 {
    line-height: 1.7;
  }
}

.leading-custom {
  &-0-7 {
    letter-spacing: 0.7px;
  }

  &-0-55 {
    letter-spacing: 0.55px;
  }

  &-1-7 {
    letter-spacing: 1.7px;
  }
}

.max-witdh {
  &-120 {
    max-width: 120px;
  }
}

.width {
  &-max-content {
    width: max-content;
  }

  &-199 {
    width: 199px;
  }
}

.height {
  &-312 {
    height: 312px;
  }

  &-min-100 {
    min-height: 100px;
  }
}

.border-radius {
  &-7 {
    border-radius: 7px;
  }
}
