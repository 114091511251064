@import '../../../styles/palette.scss';

.editable-welcome--wrapper {
  padding: 40px;
}

.editable-welcome--title {
  margin-top: 0;
  margin-left: 20px;
  margin-bottom: 40px;
}

.editable-welcome--description {
  margin: 30px 20px 30px 20px;
}

.editable-welcome--subscribe-title {
  margin-top: 1.4rem;
  color: $moon-gray;
  font-size: 11px;
  font-weight: 500;

  &:hover, &:active:not(:disabled):after {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  }
}

.editable-welcome--subscribe-title :focus,
.editable-welcome--subscribe-title :active {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
}