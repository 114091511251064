@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.alice-carousel {
  & > div {
    margin: 10px;
  }

  .alice-carousel__wrapper {
    .meeting-card {
      overflow: unset;
    }
    
    .alice-carousel__stage {
      padding: 15px 5px;
      margin-left: -13px;
    }
  }
}

.alice-carousel__next-btn,
.alice-carousel__prev-btn {
  top: 0;
  width: 35px;
  cursor: pointer;
  position: absolute;
  transform: translateY(180%);
}

.alice-carousel__next-btn {
  right: -40px;
}

.alice-carousel__prev-btn {
  left: -60px;
}

.alice-carousel__play-btn-item {
  border: 1px solid #000;
}

.alice-carousel__next-btn:has(.next-button--disabled),
.alice-carousel__prev-btn:has(.prev-button--disabled) {
  pointer-events: none;
}